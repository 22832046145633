<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <vs-select
            placeholder="10"
            autocomplete
            vs-multiple
            v-model="dataTableParams.limit"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>
          <vs-select
            autocomplete
            vs-multiple
            v-model="dataTableParams.filter"
            class="mr-6"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.label"
              v-for="(item, index) in filterList"
            />
          </vs-select>
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName">First Name</vs-th>
          <vs-th sort-key="lastName">Last Name</vs-th>
          <vs-th sort-key="userType">Type</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].firstName">{{
              data[indextr].firstName | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].firstName">{{
              data[indextr].lastName | capitalize
            }}</vs-td>
            <vs-td :data="data[indextr].userType">{{
              data[indextr].userType
            }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].status">
              <p
                v-if="data[indextr].status === 'Pending_Approval'"
                class="text-sm"
              >
                Pending Approval
              </p>
              <p v-else class="text-sm">{{ data[indextr].status }}</p>
            </vs-td>
            <vs-td>{{ data[indextr].hasApprovedByAdmin }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <assign-nurse
                style="float: left;"
                class="m-1"
                v-if="
                  data[indextr].userType === 'nurse' ||
                  data[indextr].userType === 'basic' ||
                  data[indextr].userType === 'clinic-nurse'
                "
                :nurseId="data[indextr]._id"
                :associatedIds="
                  data[indextr].associatedClinicIds.map(e => e.clinicId)
                "
              />
              <request-doctor
                v-if="data[indextr].userType === 'doctor'"
                style="float:left"
                class="m-1"
                :doctorId="data[indextr]._id"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import requestDoctorAssociation from "../../components/others/requestDoctorAssociation";
import assignNurse from "../../components/others/assignNurse";

export default {
  name: "doctor-table",
  components: {
    "request-doctor": requestDoctorAssociation,
    "assign-nurse": assignNurse
  },
  props: {
    canRequestAssociation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All"
      },
      serverResponded: false,
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All" },
        { value: "doctor", label: "Doctor" },
        { value: "nurse", label: "Nurse" },
        { value: "basic", label: "Basic User" }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("doctor", ["fetchNurseDoctors"]),
    getDoctorNurseList() {
      let self = this;
      this.fetchNurseDoctors(self.dataTableParams).then(res => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        this.serverResponded = true;
        self.noDataText = "No Doctor Available";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getDoctorNurseList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getDoctorNurseList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getDoctorNurseList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: this.viewRoute, params: { doctorId: id } });
    },
    createDoctorHandler() {
      this.$router.push({ name: this.createRoute });
    },
    exportCsvHandler() {
      let _instance = this;
      let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

      //csvLinkInstance.click();
      this.exportCsv()
        .then(async res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "freshclinic-doctors.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getDoctorNurseList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getDoctorNurseList();
      }
    },
    "dataTableParams.filter": function(newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getDoctorNurseList();
      }
    }
  },
  created() {
    this.getDoctorNurseList();
  }
};
</script>
